// import fullpage from 'fullpage.js';
// import lottie from 'lottie-web';
// When using fullPage extensions replace the previous import
// of fullpage.js for this file
//import fullpage from 'fullpage.js/dist/fullpage.extensions.min';

// Initializing it
// const fullPageInstance = new fullpage('#content', {
//     anchors:['tech', 'product'],
//     licenseKey: ''
// });


window.onscroll = function () {
    let technologyTop = document.getElementById("technology").getBoundingClientRect().top;
    let productTop = document.getElementById("product").getBoundingClientRect().top;

    if(technologyTop<=10 && technologyTop>-1140){
        setActive('tech_link');
    }else {
        removeClass(document.getElementById('tech_link'),'active');
    }



    //let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    //console.log();//,.getBoundingClientRect().top);
};

function setActive(id){
    const links = ['tech_link'];

    for(let i=0;i<links.length;i++){
        if(id!=links[i]){
            removeClass(document.getElementById(links[i]),'active');
        }else{
            addClass(document.getElementById(links[i]),'active');
        }
    }
}

function hasClass(elem, cls) {
    //console.log('has:',elem,document.getElementById('home_link'));

    cls = cls || '';
    if (cls.replace(/\s/g, '').length == 0) return false; //当cls没有参数时，返回false
    return new RegExp(' ' + cls + ' ').test(' ' + elem.className + ' ');
}

function addClass(elem, cls) {
    //console.log('add:',elem);

    if (!hasClass(elem, cls)) {
        elem.className = elem.className == '' ? cls : elem.className + ' ' + cls;
    }
}

function removeClass(elem, cls) {
    //console.log('remove:',elem);

    if (hasClass(elem, cls)) {
        let newClass = ' ' + elem.className.replace(/[\t\r\n]/g, '') + ' ';
        while (newClass.indexOf(' ' + cls + ' ') >= 0) {
            newClass = newClass.replace(' ' + cls + ' ', ' ');
        }
        elem.className = newClass.replace(/^\s+|\s+$/g, '');
    }
}

var _hmt = _hmt || [];
(function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?eb1ddf092e45a400e9eba36253110624";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
})();
